import React from 'react'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
import { ABOUTUS } from '../../components/constant'
const Structure = () => {
  return (
    <div>
      <Layout>
        <SEO title="IRS Structure" />
        <PageStructure title="Structure" desc="TESTIG" mode={ABOUTUS} />
      </Layout>
    </div>
  )
}

export default Structure
